import { isWindowBelowWidth } from '../utils/window-matches';

const initBlock = ( $block ) => {
	/**
	 * Initialize the vivus.
	 */
	app.loadVivus( ( Vivus ) => {
		const duration = 200;
		const $svg = $block.find( '.js-vivus-arrow' );

		const svg = $svg[0];

		new Vivus( svg, {
			type: 'oneByOne',
			duration,
		} );
	} );

	/**
	 * Initialize the swiper.
	 */
	app.loadSwiper( ( Swiper ) => {
		$block.find( '.js-portfolio' ).each( ( _, portfolio ) => {
			const options = {
				spaceBetween: 16,
				centeredSlides: true,
				loop: false,
				initialSlide: 2,
				breakpoints: {
					320: {
						slidesPerView: 1.2,
					},
					400: {
						slidesPerView: 2.2,
					},
					600: {
						slidesPerView: 3,
					},
				},
			};

			let swiper = null;

			$( window ).on( 'resize load', () => {
				if ( isWindowBelowWidth( 1024 ) ) {
					if ( swiper ) {
						return;
					}

					swiper = new Swiper( portfolio, options );
				} else {
					if ( !swiper ) {
						return;
					}

					swiper.destroy( true, true );
					swiper = null;
				}
			} );
		} );
	} );
};

app.loadBlock( initBlock, 'hero-wave', '.js-section-hero-wave' );
